/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useQuery } from 'react-fetching-library';
import { Snackbar } from '@material-ui/core';

import { ConfirmCollectiveUIContainer } from './confirmCollectiveUI/ConfirmCollectiveUIContainer';
import { HighSchoolSelfRegisterContainer } from './selfRegistration/HighSchoolSelfRegisterContainer';
import { CompleteSelfRegistrationContainer } from './completeSelfRegistration/CompleteSelfRegistrationContainer';
import { InstitutionEventDataResponse } from 'api/actions/selfRegistration/selfRegistration.types';
import { createFetchInstitutionEventData } from 'api/actions/selfRegistration/selfRegistration.actions';
import { useStyles } from './HighSchoolSelfRegistration.styles';
import { SelectInstituitionContainer } from './selectInstituition/SelectInstituitionContainer';
import { Loader } from 'shared/components';

const amazonPrimeEnableInstitutionIds = process.env.REACT_APP_PRIME_INSTITUTION_IDS;

export const SelfRegistrationContainer = () => {
  let isCollective;
  let instituteLogo;
  let isLoading = true;
  let instituitionEventDetails: InstitutionEventDataResponse | undefined;
  const isRetailtest = false;
  let isAllowForAmazonMusic;
  let isShowAmazonPrime;

  const { pathname } = useLocation();
  const styles = useStyles();

  const segments = pathname.split('/');
  const domain = segments[segments.length - 2];
  const partOfInstitutionId = segments[segments.length - 1];

  const [isProhibitedEmailModalOpen, setIsProhibitedEmailModalOpen] = useState<React.ReactNode | null>(null);

  // Check the step
  const [activeStep, setActiveStep] = useState(0);

  const isAmazonPrimeEnableInstitution = (institutionId: string | null) => {
    if (amazonPrimeEnableInstitutionIds) {
      const institutionList = amazonPrimeEnableInstitutionIds.split(',');
      const enableInstitutions = institutionList.filter(item => item === institutionId);

      if (enableInstitutions.length > 0) {
        return true;
      }
    }

    return false;
  };

  const [participantDetails, setParticipantDetails] = useState({
    id: '',
    wooCommercePermalink: '',
  });
  const [instituitionEventId, setInstituitionEventId] = useState('');
  const [isRetail, setIsRetail] = useState<boolean>(isRetailtest);

  if (domain) {
    const { payload: instituitionData, loading } = useQuery<InstitutionEventDataResponse>(
      createFetchInstitutionEventData(domain, partOfInstitutionId),
    );
    isLoading = loading;
    instituteLogo = instituitionData?.institutionLogo;
    isCollective = instituitionData?.isAllowCollctiv;
    instituitionEventDetails = instituitionData;
    isAllowForAmazonMusic = instituitionData?.isAllowForAmazonMusic;
    isShowAmazonPrime = isAmazonPrimeEnableInstitution((instituitionData?.id as string) || null);
  }
  const handleSnackBarClose = () => {
    setIsProhibitedEmailModalOpen(null);
  };

  if (isLoading) {
    return (
      <div>
        <Loader fullScreen />
      </div>
    );
  }

  return (
    <>
      {activeStep === 0 && instituitionEventDetails && (
        <SelectInstituitionContainer
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          instituteLogo={instituteLogo}
          isCollective={isCollective}
          isAllowForAmazonMusic={isAllowForAmazonMusic}
          isLoading={isLoading}
          setIsRetail={setIsRetail}
          setInstituitionEventId={setInstituitionEventId}
          instituitionEventDetails={instituitionEventDetails}
        />
      )}
      {activeStep === 1 && (
        <HighSchoolSelfRegisterContainer
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          eventId={instituitionEventId}
          setParticipantDetails={setParticipantDetails}
          isCollective={isCollective}
          isRetail={isRetail}
          instituteLogo={instituteLogo}
          setSnackbarMessage={(message: React.ReactNode) => setIsProhibitedEmailModalOpen(message)}
          queryParamKeys="instituitionId"
          isAllowForAmazonMusic={isAllowForAmazonMusic}
        />
      )}
      {activeStep === 2 && (isCollective || isAllowForAmazonMusic) && (
        <ConfirmCollectiveUIContainer
          activeStep={activeStep}
          participantId={participantDetails.id}
          setActiveStep={setActiveStep}
          instituteLogo={instituteLogo}
          isCollective={isCollective}
          isRetail={isRetail}
          queryParamKeys="instituitionId"
          isAllowForAmazonMusic={isAllowForAmazonMusic}
          isAllowForAmazonPrime={isShowAmazonPrime}
        />
      )}
      {activeStep === 3 && (
        <CompleteSelfRegistrationContainer
          activeStep={activeStep}
          participantWooCommercePermalink={participantDetails.wooCommercePermalink}
          setActiveStep={setActiveStep}
          instituteLogo={instituteLogo}
          isCollective={isCollective}
          isRetail={isRetail}
          queryParamKeys="instituitionId"
          isAllowForAmazonMusic={isAllowForAmazonMusic}
          isRegisterFlow
          institutionId={instituitionEventDetails?.id}
        />
      )}

      <Snackbar
        open={!!isProhibitedEmailModalOpen}
        onClose={handleSnackBarClose}
        message={isProhibitedEmailModalOpen}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        className={styles.snackBar}
        autoHideDuration={10000}
      />
    </>
  );
};
