/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { Button, Typography } from '@material-ui/core';

import { CustomStepper } from '../CustomStepper';

import { StudentLoginHeader } from 'app/studentLoginPage/studentLoginHeader/studentLoginHeader';
import { useStyles } from './ConfirmCollectiveUI.styles';
import { CompleteSelfRegistrationProps } from './ConfirmCollectiveUI.types';
import { useLocale } from 'shared/hooks/useLocale/useLocale';
import { Loader } from 'shared/components';
import AmazonMusic from 'assets/amazon-music.png';
import AmazonPrime from 'assets/amazon-prime.png';

export const CompleteCollective = ({
  activeStep,
  handleButtonClick,
  instituteLogo,
  isCollective,
  isRetail,
  isLoading,
  queryParamKeys,
  isAllowForAmazonMusic,
  isAllowForAmazonPrime,
  handleButtonAmazonMusicClick,
  handleNextAmazonCancel,
}: CompleteSelfRegistrationProps) => {
  const styles = useStyles({});
  const { formatMessage } = useLocale();

  const handleActivateClick = (allowDonation: 'yes' | 'no') => {
    handleButtonClick({ allowDonation });
  };

  return (
    <>
      {(queryParamKeys === 'instituitionId' ? activeStep === 2 : activeStep === 1) && (
        <div className={styles.mainContainer}>
          <StudentLoginHeader />

          <div className={styles.container}>
            {isLoading ? (
              <Loader fullScreen />
            ) : (
              <div className={styles.cardWrapper}>
                {isAllowForAmazonMusic ? (
                  <>
                    <div className={styles.imgContainer}>
                      <img src={instituteLogo} alt="institute logo" className={styles.logoImage} />
                    </div>
                    <div className={styles.titleContainer}>
                      <CustomStepper
                        activeStep={activeStep}
                        isRetail={isRetail}
                        isCollective={isCollective}
                        isAllowForAmazonMusic={isAllowForAmazonMusic}
                      />
                      <Typography className={styles.rootTextAmazon}>
                        {formatMessage({ id: 'highSchool.amazon_title' })}
                      </Typography>
                    </div>
                    <div className={styles.cardAmazon}>
                      <Typography className={styles.bottomContainer}>
                        {isAllowForAmazonPrime
                          ? formatMessage({ id: 'highSchool.amazon_prime_question' })
                          : formatMessage({ id: 'highSchool.amazon_music_question' })}
                      </Typography>

                      <div className={styles.amazonCardContainer}>
                        <Button
                          type="submit"
                          // size="large"
                          className={styles.buttonContainer}
                          onClick={() => handleButtonAmazonMusicClick()}
                          disabled={isLoading}
                        >
                          {formatMessage({ id: 'highSchool.collective_active_button' })}
                        </Button>
                        <Typography className={styles.amazonTitle}>
                          {formatMessage({ id: 'highSchool.amazon_music_title' })}
                        </Typography>
                        <Typography className={styles.amazonDescription}>
                          This is thanks to previous grads. who activated an{' '}
                          <span className={styles.amazonTitle}>
                            {isAllowForAmazonPrime ? 'Amazon Prime' : 'Amazon Music Unlimited'}
                          </span>{' '}
                          free trial.
                          {/* {formatMessage({ id: 'highSchool.amazon_music_description' })} */}
                        </Typography>
                        <Typography className={styles.amazonDescription1}>
                          {formatMessage({ id: 'highSchool.amazon_music_description1' })}
                        </Typography>
                        <Typography className={styles.amazonDescription2}>
                          {formatMessage({ id: 'highSchool.amazon_music_description2' })}
                        </Typography>
                        <Typography className={styles.amazonDescription3}>
                          {formatMessage({ id: 'highSchool.amazon_music_description3' })}
                        </Typography>
                        <div
                          className={styles.amazonButtonSecondContainer}
                          onClick={() => handleNextAmazonCancel()}
                          onKeyDown={() => handleNextAmazonCancel()}
                        >
                          {formatMessage({ id: 'highSchool.amazon_music_cancel_button' })}
                        </div>
                        <div className={styles.amazonLogoImage}>
                          <img src={isAllowForAmazonPrime ? AmazonPrime : AmazonMusic} alt="amazon logo" />
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className={styles.imgContainer}>
                      <img src={instituteLogo} alt="institute logo" className={styles.logoImage} />
                    </div>
                    <div className={styles.titleContainer}>
                      <CustomStepper
                        activeStep={activeStep}
                        isRetail={isRetail}
                        isCollective={isCollective}
                        isAllowForAmazonMusic={isAllowForAmazonMusic}
                      />
                      <Typography className={styles.rootText}>
                        {formatMessage({ id: 'highSchool.collective_title' })}
                      </Typography>
                    </div>
                    <div className={styles.card}>
                      <Typography className={styles.bottomContainer}>
                        {formatMessage({ id: 'highSchool.collective_confirm_question' })}
                      </Typography>

                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <Button
                          type="submit"
                          // size="large"
                          className={styles.buttonContainer}
                          onClick={() => handleActivateClick('yes')}
                          disabled={isLoading}
                        >
                          {formatMessage({ id: 'highSchool.collective_active_button' })}
                        </Button>

                        <div
                          className={styles.buttonSecondContainer}
                          onClick={() => handleActivateClick('no')}
                          onKeyDown={() => handleActivateClick('no')}
                        >
                          {formatMessage({ id: 'highSchool.collective_later_button' })}
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};
