/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import { Button, IconButton, TextField, Typography } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import moment from 'moment';
import { Close } from '@material-ui/icons';

import { CustomStepper } from '../CustomStepper';

import { useLocale } from 'shared/hooks/useLocale/useLocale';
import { StudentLoginHeader } from 'app/studentLoginPage/studentLoginHeader/studentLoginHeader';
import { useStyles } from './SelectInstituition.styles';
import { MainHeading } from 'app/avUserRoot/collabloratorUpload/uploadMedia/eventInformations/EventInformation.styles';
import { EventData, EventOption, SelfRegistrationSelectInstituitionProps } from './SelectInstituition.types';
import { Form } from 'shared/components/form/Form';
import { SelectField } from 'shared/components/controls';
import { Loader } from 'shared/components';
import { ClipPurchaseTypes } from 'api/actions/selfRegistration/selfRegistration.types';

export const SelectInstituitionPage = ({
  onSubmit,
  activeStep,
  instituteLogo,
  isCollective,
  isAllowForAmazonMusic,
  isLoading,
  instituitionEventDetails,
  setInstituitionEventId,
  setIsRetail,
}: SelfRegistrationSelectInstituitionProps) => {
  const styles = useStyles({});
  const { formatMessage } = useLocale();

  const initialValues = {
    isAllowForMarketing: true,
  };

  const eventDetails: EventData[] = Object.keys(instituitionEventDetails?.events).map(
    year =>
      ({
        year,
        events: instituitionEventDetails?.events[year as keyof typeof instituitionEventDetails.events] || [],
      } as EventData),
  );

  const yearOptions = eventDetails
    .sort((a, b) => Number(b.year) - Number(a.year)) // Convert year to a number
    .map(item => ({
      value: item.year,
      label: item.year,
    }));

  const [selectedYear, setSelectedYear] = useState(yearOptions.length === 1 ? yearOptions[0].value : '');
  const [selectedEvent, setSelectedEvent] = useState('');

  const eventOptions =
    eventDetails
      .find(item => item.year === selectedYear)
      ?.events.map(event => ({
        value: event.id,
        label: `${moment(`${event.startDateOnly} ${event.startTimeOnly}`).format('dddd, Do MMMM - h:mma')} (${
          event.name
        })`,
        clipPurchaseType: event.clipPurchaseType,
      })) || [];

  // useEffect(() => {
  //   if (selectedYear && !selectedEvent) {
  //     const latestEvent = eventOptions[0];

  //     if (latestEvent) {
  //       setSelectedEvent(latestEvent.value);
  //       setInstituitionEventId(latestEvent.value);
  //       setIsRetail(
  //         latestEvent.clipPurchaseType === null
  //           ? false
  //           : latestEvent.clipPurchaseType === ClipPurchaseTypes.RetailEndUserPays,
  //       );
  //     }
  //   }
  // }, [eventOptions, selectedEvent, selectedYear, setInstituitionEventId, setIsRetail]);

  const handleYearChange = (e: any) => {
    setSelectedYear(e.target.value);
    setSelectedEvent('');
  };

  const handleEventChange = (event: React.ChangeEvent<{}>, value: EventOption | null) => {
    if (value) {
      const selectedEventId = value.value;
      const selectedEventDetails = eventOptions.find(item => item.value === selectedEventId);

      if (selectedEventDetails) {
        setSelectedEvent(selectedEventId);
        setInstituitionEventId(selectedEventId);
        setIsRetail(
          selectedEventDetails.clipPurchaseType === null
            ? false
            : selectedEventDetails.clipPurchaseType === ClipPurchaseTypes.RetailEndUserPays,
        );
      }
    }
  };

  return (
    <>
      {activeStep === 0 && (
        <div className={styles.mainContainer}>
          <StudentLoginHeader />

          <div className={styles.imgContainer}>
            {isLoading ? (
              <Loader />
            ) : (
              instituteLogo && <img src={instituteLogo} alt="institute logo" className={styles.logoImage} />
            )}
          </div>
          <div className={styles.container}>
            <div className={styles.cardWrapper}>
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <CustomStepper
                  isCollective={isCollective}
                  activeStep={activeStep}
                  isAllowForAmazonMusic={isAllowForAmazonMusic}
                />
                <Typography className={styles.titleContainer}>
                  {formatMessage({ id: 'highSchool.register_welcome' })}
                </Typography>
                <Typography className={styles.subTitle}>
                  {formatMessage({ id: 'highSchool.register_welcome_subtitle' })}
                </Typography>
              </div>

              <div className={styles.card}>
                {isLoading ? (
                  <Loader />
                ) : (
                  <Form
                    onSubmit={onSubmit}
                    initialValues={initialValues}
                    errorSeparator={2}
                    render={({ handleSubmit }) => (
                      <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column' }}>
                        <MainHeading className={styles.header}>
                          {formatMessage({ id: 'highSchool.select_graduate_year_title' })}
                        </MainHeading>
                        <br />
                        <SelectField
                          name="year"
                          label="Select year"
                          options={yearOptions}
                          defaultValue={yearOptions.length === 1 ? yearOptions[0].value : ''}
                          onClick={handleYearChange}
                        />
                        <span className={styles.eventText}>Select event</span>
                        <Autocomplete
                          className={styles.eventDropdown}
                          popupIcon=""
                          size="medium"
                          options={eventOptions}
                          getOptionLabel={option => option.label}
                          value={eventOptions.find(option => option.value === selectedEvent) || null}
                          onChange={handleEventChange}
                          renderInput={params => <TextField {...params} size="medium" variant="outlined" fullWidth />}
                          disabled={!selectedYear}
                          closeIcon={
                            <IconButton size="small" className={styles.closeIcon} onClick={() => setSelectedEvent('')}>
                              <Close />
                            </IconButton>
                          }
                        />

                        {/* <SelectField
                          name="event"
                          label="Select event"
                          options={eventOptions}
                          defaultValue=""
                          onClick={handleEventChange}
                          disabled={!selectedYear}
                        /> */}
                        <Button
                          type="submit"
                          size="large"
                          className={styles.buttonContainer}
                          disabled={isLoading || !selectedEvent}
                        >
                          {formatMessage({ id: 'highSchool.select_year_button' })}
                          {/* {isMobile
                    ? formatMessage({ id: 'highSchool.register_button_mobile' })
                    : formatMessage({ id: 'highSchool.register_button' })} */}
                        </Button>
                      </form>
                    )}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
