import React, { useEffect, useState } from 'react';
import { useReactMediaRecorder } from 'react-media-recorder';
import { useField } from 'react-final-form';

import { useAzureUpload } from 'shared/hooks/useAzureUpload/useAzureUpload';
import { createFetchUploadMeta } from 'api/actions/participant/participant.actions';
import { useParticipantQueryData } from 'contexts/participantQueryData/participantQueryDataContext';
import 'react-h5-audio-player/lib/styles.css';
import { AudioRecorder } from './AudioRecorder';
import { AudioRecorderContainerProps } from './AudioRecoder.types';

export const AudioRecorderContainer = ({ setIsAudioUploading, setRecordingStatus }: AudioRecorderContainerProps) => {
  const [blob, setBlob] = useState();
  const { startRecording, stopRecording, mediaBlobUrl, status, clearBlobUrl } = useReactMediaRecorder({
    audio: true,
    onStop: (_, _blob) => setBlob(_blob),
  });
  const { participantId } = useParticipantQueryData();
  const { upload, fileURI, loading } = useAzureUpload({
    fetchUploadMetaAction: createFetchUploadMeta(participantId),
  });
  setRecordingStatus(status);
  const {
    input: { onChange: pronunciationAudioChange, value: pronunciationAudio },
  } = useField<string>('pronunciationAudio', {
    subscription: { value: true, valid: true },
  });

  const {
    input: { onChange: pronunciationAudioURIChange, value: pronunciationAudioURI },
  } = useField<string>('pronunciationAudioURI');

  useEffect(() => {
    pronunciationAudioURIChange(fileURI);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileURI]);

  useEffect(() => {
    if (blob) {
      upload(new File([blob], `${participantId}.wav`));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blob]);

  useEffect(() => {
    setIsAudioUploading(loading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  const handleDelete = () => {
    pronunciationAudioURIChange(null);
    pronunciationAudioChange(null);
    clearBlobUrl();
  };

  return (
    <>
      <AudioRecorder
        handleDelete={handleDelete}
        pronunciationAudioURI={pronunciationAudioURI}
        startRecording={startRecording}
        stopRecording={stopRecording}
        mediaBlobUrl={mediaBlobUrl}
        status={status}
        pronunciationAudio={pronunciationAudio}
      />
    </>
  );
};
