import React from 'react';

import { CompleteSelfRegistration } from './CompleteSelfRegistration';
import { CompleteSelfRegistrationContainerProps } from './CompleteSelfRegistration.types';

export const CompleteSelfRegistrationContainer = ({
  activeStep,
  participantWooCommercePermalink,
  setActiveStep,
  instituteLogo,
  institutionId,
  isCollective,
  isRetail,
  queryParamKeys,
  isRegisterFlow,
  ...props
}: CompleteSelfRegistrationContainerProps) => {
  return (
    <CompleteSelfRegistration
      activeStep={activeStep}
      handleButtonClick={undefined}
      participantWooCommercePermalink={participantWooCommercePermalink}
      setActiveStep={setActiveStep}
      instituteLogo={instituteLogo}
      institutionId={institutionId}
      isCollective={isCollective}
      isRetail={isRetail}
      queryParamKeys={queryParamKeys}
      isRegisterFlow={isRegisterFlow}
      {...props}
    />
  );
};
