import React from 'react';

import { SelectInstituitionContainerProps } from './SelectInstituition.types';
import { SelectInstituitionPage } from './SelectInstituition';

export const SelectInstituitionContainer = ({
  activeStep,
  setActiveStep,
  instituteLogo,
  isCollective,
  isAllowForAmazonMusic,
  isLoading,
  ...props
}: SelectInstituitionContainerProps) => {
  const onSubmit = async () => {
    setActiveStep(activeStep + 1);
  };

  return (
    <SelectInstituitionPage
      activeStep={activeStep}
      onSubmit={onSubmit}
      instituteLogo={instituteLogo}
      isCollective={isCollective}
      isAllowForAmazonMusic={isAllowForAmazonMusic}
      isLoading={isLoading}
      {...props}
    />
  );
};
