import React, { useEffect, useState } from 'react';
import { Button, Card, FormControlLabel, Typography } from '@material-ui/core';
import { useQuery, useMutation } from 'react-fetching-library';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router';
import { FormSpy } from 'react-final-form';
import { isUndefined } from 'lodash';
import { RHAP_UI } from 'react-h5-audio-player';

import { Icon } from 'shared/components';
import { createFetchParticipantData, createUploadParticipantData } from 'api/actions/participant/participant.actions';
import img1 from 'assets/bitmap_2.jpg';
import img3 from 'assets/bitmap_4.jpg';
import img4 from 'assets/bitmap.jpg';
import playIcon from 'assets/icons/play.svg';
import { useStyles } from './StudentNameListenerEdit.styles';
import { Footer } from 'shared/templates/main/footer/Footer';
import { AppRoute } from 'app/routing/AppRoute.enum';
import { InputField, Switch } from 'shared/components/controls';
import { useTextToSpeech } from 'app/textToSpeech/useTextToSpeech';
import { useParticipantQueryData } from 'contexts/participantQueryData/participantQueryDataContext';
import { useUpdateParticipantData, useParticipantData } from 'contexts/participantData/participantDataContext';
import { NameRecordings, ParticipantDataResponse } from 'app/root/Root.types';
import { StudentLoginHeader } from 'app/studentLoginPage/studentLoginHeader/studentLoginHeader';
import { Form } from 'shared/components/form/Form';
// import { StudentClipBuyModalModal } from './studentClipBuyPoll/StudentClipBuyPollModal';
import { StudentNameEditConfirmModal } from './studentNameEditConfirm/StudentNameEditConfirmModal';
import { CreateUploadParticipantDataBody } from 'api/actions/participant/participant.types';
import { AudioRecorderContainer } from './audioRecorder/AudioRecorderContainer';
import { AudioPlayerStyled } from './audioRecorder/AudioRecorder.styles';

export const StudentNameListenerEdit = () => {
  const { formatMessage } = useIntl();
  const [isEditButtonClicked, setEditButtonClicked] = useState(false);
  // const [isEditConfirm, setEditConfirm] = useState(false);
  const [isRecorder, setRecorderClicked] = useState(false);
  const [isAudioUploading, setIsAudioUploading] = useState(false);

  const [isClipBuy, setClipBuy] = useState(false);
  const [nameReadingData, setNameReadingData] = useState<CreateUploadParticipantDataBody>();
  const [recordingStatus, setRecordingStatus] = useState<string>('');

  const { push } = useHistory();
  const { participantId } = useParticipantQueryData();

  const { mutate, loading: updateParticipantLoading } = useMutation(createUploadParticipantData);

  const {
    fullName,
    producerLanguageInfo,
    pronunciationName,
    languageId,
    voiceActorId,
    audioPath,
  } = useParticipantData();

  const [updatePhoneticName, setUpdatePhoneticName] = useState(pronunciationName);

  const updateParticipantData = useUpdateParticipantData();

  const { loading, payload, error } = useQuery<ParticipantDataResponse>(createFetchParticipantData(participantId));

  const { translateTextToSpeech, playing } = useTextToSpeech();

  useEffect(() => {
    if (!loading && !error && !!payload) {
      updateParticipantData(payload);
    }
  }, [error, loading, payload, updateParticipantData]);

  const classes = useStyles();

  useEffect(() => {
    if (!participantId) {
      push(AppRoute.studentLoginPage);
    }
  }, [push, participantId]);

  const play = () => {
    const values = {
      languageId: payload?.producerLanguageInfo[0].languageId,
      voiceActorId: payload?.producerLanguageInfo[0].voiceActors[0].id,
      pronunciationName: !pronunciationName ? fullName : pronunciationName,
    };
    translateTextToSpeech({
      textToSpeech: values.pronunciationName,
      language: values.languageId,
      voiceActor: values.voiceActorId,
    });
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onSubmit = async (value: any) => {
    if (isRecorder) {
      setNameReadingData({
        ...value,
        participantId,
        terms: true,
        audioPath: value.fileURI,
      } as CreateUploadParticipantDataBody);
    } else {
      setNameReadingData({
        ...value,
        participantId,
        terms: true,
        nameRecordings: NameRecordings.AudioAi,
      } as CreateUploadParticipantDataBody);
    }
    // setEditConfirm(true);

    if (!isRecorder) {
      if (
        !value?.pronunciationName ||
        value?.pronunciationName === pronunciationName ||
        fullName === value?.pronunciationName
      ) {
        await mutate({
          ...value,
          participantId,
          terms: true,
          nameReaderUpVote: true,
          state: 'confirmed',
          voiceActorId: payload?.producerLanguageInfo[0].voiceActors[0].id,
          audioPath: null,
        });
      } else {
        await mutate({
          ...value,
          participantId,
          terms: true,
          nameReaderUpVote: false,
          voiceActorId: payload?.producerLanguageInfo[0].voiceActors[0].id,
          audioPath: null,
        });
      }
    } else {
      await mutate({
        participantId,
        terms: true,
        nameReaderUpVote: true,
        nameRecordings: NameRecordings.SelfRecord,
        audioPath: value?.pronunciationAudioURI,
      });
    }
    setClipBuy(true);
  };

  const handlePollModalClose = async (nameReaderUpVote?: boolean) => {
    if (isUndefined(nameReaderUpVote)) {
      // setEditConfirm(false);
      setClipBuy(false);
      push(`${AppRoute.studentLoginPage}?id=${payload?.eventId}`);
    }
    setClipBuy(false);
    push(`${AppRoute.studentLoginPage}?id=${payload?.eventId}`);
  };

  const handleOnChange = (checked: boolean) => {
    if (checked) {
      setRecorderClicked(true);
    } else {
      setRecorderClicked(false);
    }
  };

  const participantData = useParticipantData();

  const handleDelete = async () => {
    updateParticipantData({
      ...participantData,
      audioPath: null,
    });
    await mutate({
      audioPath: null,
      participantId,
      terms: true,
    });
  };

  return (
    <>
      <div className={classes.mainContainer}>
        <div className={classes.container}>
          <div>
            <StudentLoginHeader />
            <Typography className={classes.Title}>{`Hi, ${fullName}`}</Typography>
          </div>
          <div className={classes.cardContainer}>
            {isEditButtonClicked ? (
              <>
                <Card className={classes.card}>
                  {!isRecorder && (
                    <>
                      <Typography className={classes.editCardInnerParticipantName}>{fullName}</Typography>
                      <div className={classes.phoneticLabel}>
                        <div>
                          <Typography className={classes.phoneticText}>Phonetic Spelling :</Typography>
                        </div>
                        <div className={classes.phoneticName}>
                          <Typography className={classes.editCardInnerParticipantRoleOne}>
                            {updatePhoneticName === undefined || updatePhoneticName === ''
                              ? fullName
                              : updatePhoneticName}
                          </Typography>
                        </div>
                      </div>
                    </>
                  )}

                  <div>
                    <Form
                      onSubmit={onSubmit}
                      subscription={{}}
                      initialValues={{
                        pronunciationName: pronunciationName || fullName,
                        languageId: languageId || (producerLanguageInfo && producerLanguageInfo[0]?.languageId),
                        voiceActorId: voiceActorId || undefined,
                      }}
                      render={({ handleSubmit }) => (
                        <FormSpy>
                          {({ values }) => {
                            setUpdatePhoneticName(values.pronunciationName);

                            return (
                              <form onSubmit={handleSubmit}>
                                {isRecorder ? (
                                  <>
                                    <Typography className={classes.voiceRecordingText}>
                                      {formatMessage({ id: 'av_user.student_Name_Listener.voice_recording' })}
                                    </Typography>
                                    <div className={classes.audioContainer}>
                                      <AudioRecorderContainer
                                        setIsAudioUploading={setIsAudioUploading}
                                        setRecordingStatus={setRecordingStatus}
                                      />
                                    </div>
                                    <div className={classes.audioRecorderConfirmButton}>
                                      <Button
                                        type="submit"
                                        variant="outlined"
                                        className={classes.editConfirmButton}
                                        disabled={
                                          recordingStatus === `${'recording' || 'stopping'}` || isAudioUploading
                                        }
                                      >
                                        <img src={playIcon} alt="icon" className={classes.listenButtonIcon} />
                                        {formatMessage({ id: 'av_user.student_Name_Listener.button_confirm' })}
                                      </Button>
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <div className={classes.formControl}>
                                      <Typography className={classes.labelTop}>
                                        {formatMessage({ id: 'av_user.student_Name_Listener.edit_name_label' })}
                                      </Typography>

                                      <InputField
                                        name="pronunciationName"
                                        variant="outlined"
                                        defaultValue={pronunciationName || fullName}
                                        className={classes.editCardInnerParticipantRole}
                                        autoFocus={isEditButtonClicked}
                                      />
                                    </div>

                                    <div className={classes.dividerLine} />
                                    <div className={classes.editButtonContainer}>
                                      <Button
                                        disabled={playing}
                                        type="button"
                                        variant="outlined"
                                        onClick={() =>
                                          translateTextToSpeech({
                                            textToSpeech: values.pronunciationName,
                                            language: payload?.producerLanguageInfo[0].languageId,
                                            voiceActor: payload?.producerLanguageInfo[0].voiceActors[0].id,
                                          })
                                        }
                                        className={classes.editlistenButton}
                                      >
                                        <img src={playIcon} alt="icon" className={classes.listenButtonIcon} />

                                        {formatMessage({ id: 'av_user.student_Name_Listener.button_listen' })}
                                      </Button>
                                      <Button type="submit" variant="outlined" className={classes.editConfirmButton}>
                                        <img src={playIcon} alt="icon" className={classes.listenButtonIcon} />
                                        {formatMessage({ id: 'av_user.student_Name_Listener.button_confirm' })}
                                      </Button>
                                    </div>
                                  </>
                                )}
                              </form>
                            );
                          }}
                        </FormSpy>
                      )}
                    />
                  </div>
                </Card>
                <div className={classes.switchWithLabel}>
                  <FormControlLabel control={<Switch onChange={handleOnChange} />} label="Switch to Self-record" />
                </div>
              </>
            ) : (
              <Card className={classes.card}>
                <>
                  <div className={classes.buttonMainContainer}>
                    <Typography className={classes.editCardInnerParticipantName}>{fullName}</Typography>
                    <div className={classes.phoneticLabel}>
                      <div>
                        <Typography className={classes.editCardInnerParticipantRoleOne}>Phonetic Spelling :</Typography>
                      </div>
                      <div className={classes.phoneticName}>
                        <Typography className={classes.editCardInnerParticipantRoleOne}>
                          {pronunciationName === undefined || pronunciationName === '' || pronunciationName === null
                            ? fullName
                            : pronunciationName}
                        </Typography>
                      </div>
                    </div>
                  </div>
                  <div className={classes.listenButtonContainer}>
                    {/* {payload.pr} */}
                    {audioPath ? (
                      <>
                        <AudioPlayerStyled
                          src={audioPath || ''}
                          showJumpControls={false}
                          customAdditionalControls={[RHAP_UI.MAIN_CONTROLS]}
                          customVolumeControls={[
                            <Button
                              variant="contained"
                              color="secondary"
                              size="small"
                              onClick={handleDelete}
                              type="submit"
                              // disabled={disabled}
                            >
                              <Icon icon="Delete" /> Delete
                            </Button>,
                          ]}
                          customControlsSection={[
                            RHAP_UI.MAIN_CONTROLS,
                            <Button
                              variant="contained"
                              color="secondary"
                              size="small"
                              type="submit"
                              onClick={handleDelete}
                            >
                              <Icon icon="Delete" /> Delete
                            </Button>,
                          ]}
                        />
                      </>
                    ) : (
                      <Button
                        disabled={playing}
                        type="button"
                        variant="outlined"
                        onClick={play}
                        className={classes.listenButton}
                      >
                        <img src={playIcon} alt="icon" className={classes.listenButtonIcon} />
                        {formatMessage({ id: 'av_user.student_Name_Listener.button_listen' })}
                      </Button>
                    )}
                    {/* <Button
                      disabled={playing}
                      type="button"
                      variant="outlined"
                      onClick={play}
                      className={classes.listenButton}
                    >
                      <img src={playIcon} alt="icon" className={classes.listenButtonIcon} />
                      {formatMessage({ id: 'av_user.student_Name_Listener.button_listen' })}
                    </Button> */}
                    <Button
                      type="button"
                      variant="outlined"
                      className={classes.editButton}
                      onClick={() => setEditButtonClicked(true)}
                    >
                      {formatMessage({ id: 'av_user.student_Name_Listener.button_Edit_Name' })}
                    </Button>
                  </div>
                </>
              </Card>
            )}
          </div>
          {!isEditButtonClicked && (
            <div className={classes.buttonContainer}>
              <Button
                type="button"
                variant="text"
                className={classes.confirmButton}
                // onClick={() => setEditConfirm(true)}
                onClick={() => {
                  onSubmit(nameReadingData);
                }}
                disabled={updateParticipantLoading}
              >
                {formatMessage({ id: 'av_user.student_Name_Listener.button_confirm' })}
              </Button>
            </div>
          )}

          <div className={classes.infoContainer}>
            <Card className={classes.infoTextCard}>
              <Typography className={classes.cardInnerInfoText}>
                {formatMessage({ id: 'av_user.student_Name_Listener.info_text' })}
              </Typography>
              <Typography className={classes.cardInnerInfoText}>
                {formatMessage({ id: 'av_user.student_Name_Listener.info_text_2' })}
              </Typography>
            </Card>
          </div>
          <Typography className={classes.clientText}>
            {formatMessage({ id: 'av_user.student_Name_Listener.client_Text' })}
          </Typography>
          <div className={classes.imageContainer}>
            <img src={img4} alt="HeaderImage" className={classes.images} />
            <img src={img3} alt="HeaderImage" className={classes.images} />
            <img src={img1} alt="HeaderImage" className={classes.images} />
          </div>
          <div className={classes.footerContainer}>
            <Footer />
          </div>
        </div>
      </div>
      {/* {isEditConfirm && <StudentClipBuyModalModal open={isEditConfirm} handleClose={handlePollModalClose} />} */}
      {isClipBuy && (
        <StudentNameEditConfirmModal
          open={isClipBuy}
          handleClose={handlePollModalClose}
          updateParticipantLoading={updateParticipantLoading}
        />
      )}
    </>
  );
};
