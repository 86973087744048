/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react';
// eslint-disable-next-line import/order
import { Button, IconButton, Typography, useMediaQuery, useTheme } from '@material-ui/core';

import clsx from 'clsx';

import { CustomStepper } from '../CustomStepper';

import { Image } from 'shared/components/image/Image';
import { useLocale } from 'shared/hooks/useLocale/useLocale';
import { StudentLoginHeader } from 'app/studentLoginPage/studentLoginHeader/studentLoginHeader';
import { useStyles } from './CompleteSelfRegistration.styles';
import { CompleteSelfRegistrationProps } from './CompleteSelfRegistration.types';
// import { ReactComponent as Facebook } from 'assets/icons/facebook.svg';
import { ReactComponent as WhatsApp } from 'assets/icons/whatsapp.svg';
// import { ReactComponent as LinkedIn } from 'assets/icons/linkedin.svg';
import { ReactComponent as Email } from 'assets/icons/email.svg';
import { ReactComponent as Twitter } from 'assets/icons/x-twitter.svg';
import { ReactComponent as Instagram } from 'assets/icons/insta.svg';
import { ReactComponent as TikTok } from 'assets/icons/tiktok.svg';
import { ReactComponent as SnapChat } from 'assets/icons/snapchat.svg';
import LinkedInLogo from 'assets/LI-Logo.png';

const hideClipUrlInstitutionIds = process.env.REACT_APP_HIDE_CLIPS_FOR_SELF_REGISTRATION;

export const CompleteSelfRegistration = ({
  activeStep,
  participantWooCommercePermalink,
  instituteLogo,
  institutionId,
  isCollective,
  setActiveStep,
  isRetail,
  queryParamKeys,
  isAllowForAmazonMusic,
  isRegisterFlow,
}: CompleteSelfRegistrationProps) => {
  const styles = useStyles({});
  const { formatMessage } = useLocale();
  const [linkCopied, setLinkCopied] = useState(false);
  const { breakpoints } = useTheme();

  const isMobile = useMediaQuery(breakpoints.down('sm'));

  const sendToPortal = () => {
    if (isCollective) {
      const divideKey = 'clip/';
      const splitURL = participantWooCommercePermalink.split(divideKey);

      const activePageURL = `${splitURL[0]}${divideKey}active/${splitURL[1]}`;

      window.open(activePageURL, '_blank');
      setActiveStep(0);
    } else {
      setActiveStep(0);
    }
  };

  const isHideClipUrlInstitution = (institutionIdValue: string | null) => {
    if (hideClipUrlInstitutionIds) {
      const institutionList = hideClipUrlInstitutionIds.split(',');
      const enableInstitutions = institutionList.filter(item => item === institutionIdValue);

      if (enableInstitutions.length > 0) {
        return true;
      }
    }

    return false;
  };

  const isHideClipShare = isHideClipUrlInstitution((institutionId as string) || null);

  // const redirectToPlatform = (platform: string) => {
  //   let url;

  //   switch (platform) {
  //     case 'facebook':
  //       url = `https://www.facebook.com/sharer/sharer.php?u=${participantWooCommercePermalink}?type=other`;
  //       break;
  //     case 'whatsapp':
  //       url = `https://api.whatsapp.com/send?text=${participantWooCommercePermalink}?type=other`;
  //       break;
  //     case 'linkedin':
  //       url = `https://www.linkedin.com/shareArticle?url=${participantWooCommercePermalink}?type=other`;
  //       break;
  //     case 'email':
  //       window.location.href = `mailto:?body=${participantWooCommercePermalink}?type=other`;
  //       break;
  //     case 'twitter':
  //       url = `https://twitter.com/intent/tweet?url=${participantWooCommercePermalink}?type=other`;
  //       break;
  //     default:
  //       break;
  //   }

  //   if (url) {
  //     window.open(url, '_blank');
  //   }
  // };

  // const samplePLink = 'https://yopmail-217.portals-staging.stageclip.com/clip/albert-iyenstain-3ffaa';
  const copySelfRegistration = async () => {
    await navigator.clipboard.writeText(`${participantWooCommercePermalink}?type=other`);
    setLinkCopied(true);
    setTimeout(() => {
      setLinkCopied(false);
    }, 2000);
    // toast.success(
    //   <NotificationMessage type="success">
    //     {formatMessage({ id: 'high_School_student.copy_success_message' })}
    //   </NotificationMessage>,
    // );
  };

  // const eventIdIndex = selfRegistrationURL.indexOf('self-');
  // const truncatedURL =
  //   eventIdIndex !== -1 ? selfRegistrationURL.substring(0, eventIdIndex + 'self-'.length) : selfRegistrationURL;

  return (
    <>
      {(queryParamKeys === 'instituitionId' ? activeStep === 3 : activeStep === 2) && (
        <div className={styles.mainContainer}>
          <StudentLoginHeader />

          <div className={styles.container}>
            <div className={styles.cardWrapper}>
              <div className={styles.imgContainer}>
                <img src={instituteLogo} alt="institute logo" className={styles.logoImage} />
              </div>
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
                {isRegisterFlow && (
                  <CustomStepper
                    activeStep={activeStep}
                    isCollective={isCollective}
                    isRetail={isRetail}
                    isAllowForAmazonMusic={isAllowForAmazonMusic}
                  />
                )}
                {isRegisterFlow ? (
                  <>
                    {!isHideClipShare && (
                      <>
                        <Typography className={styles.rootText}>
                          {formatMessage({ id: 'highSchool.complete_self_registration_title' })}
                        </Typography>
                        <Typography className={styles.rootSubText}>
                          {formatMessage({ id: 'highSchool.complete_self_registration_sub_title' })}
                        </Typography>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    <Typography className={styles.rootText}>
                      {formatMessage({ id: 'highSchool.complete_update_self_registration_title' })}
                    </Typography>
                    <Typography className={styles.rootSubText}>
                      {formatMessage({ id: 'highSchool.complete_update_self_registration_text' })}
                    </Typography>
                    <Typography className={styles.updateRootSubText}>
                      {formatMessage({ id: 'highSchool.complete_update_self_registration_sub_title' })}
                    </Typography>
                  </>
                )}
              </div>
              {isHideClipShare ? (
                <div>
                  <div>
                    <Typography className={styles.linkedInShareText}>
                      {formatMessage({ id: 'highSchool.complete_self_linkedin' })}
                    </Typography>
                    <Image alt="LinkedIn" src={LinkedInLogo} className={styles.linkedInLogo} />
                  </div>
                </div>
              ) : (
                <div className={styles.card}>
                  <div className={styles.addStudentLink} onClick={copySelfRegistration}>
                    <div className={styles.copyLinkContainer}>
                      {linkCopied ? (
                        <div className={styles.copiedLinkSpan}>Link Copied</div>
                      ) : (
                        <div
                          className={clsx(
                            isAllowForAmazonMusic && isMobile
                              ? styles.addStudentLinkSpanAmazon
                              : styles.addStudentLinkSpan,
                          )}
                        >
                          {participantWooCommercePermalink}
                        </div>
                      )}

                      <div
                        style={{
                          color: '#18a957',
                          width: isMobile ? '60px' : '88px',
                          fontSize: isAllowForAmazonMusic ? '12px' : '16px',
                          // padding: '0px 0px',
                          fontWeight: 800,
                          textTransform: 'capitalize',
                          display: linkCopied ? 'none' : 'flex',
                        }}
                      >
                        {isMobile && !isAllowForAmazonMusic
                          ? formatMessage({ id: 'highSchool.copy_text_mobile' })
                          : formatMessage({ id: 'highSchool.copy_text' })}
                      </div>
                    </div>
                  </div>
                  <div className={styles.socialIconContainer}>
                    <div className={styles.socialIconSubContainer}>
                      <IconButton onClick={copySelfRegistration} className={styles.socialMediaIconButton}>
                        <WhatsApp />
                      </IconButton>
                      <IconButton onClick={copySelfRegistration} className={styles.socialMediaIconButton}>
                        <Email />
                      </IconButton>
                      <IconButton onClick={copySelfRegistration} className={styles.socialMediaIconButton}>
                        <TikTok />
                      </IconButton>
                      <IconButton onClick={copySelfRegistration} className={styles.socialMediaIconButton}>
                        <SnapChat />
                      </IconButton>
                      <IconButton onClick={copySelfRegistration} className={styles.socialMediaIconButton}>
                        <Instagram />
                      </IconButton>
                      <IconButton onClick={copySelfRegistration} className={styles.socialMediaIconButton}>
                        <Twitter />
                      </IconButton>
                    </div>
                    <div>
                      {isRegisterFlow && isAllowForAmazonMusic && (
                        <Button
                          type="button"
                          size="large"
                          className={styles.buttonContainer}
                          onClick={() => sendToPortal()}
                        >
                          {formatMessage({ id: 'highSchool.complete_self_registration_finish_button' })}
                        </Button>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
            {isRegisterFlow && !isAllowForAmazonMusic && (
              <div className={styles.finishButtonContainer}>
                <Button type="button" size="large" className={styles.buttonContainer} onClick={() => sendToPortal()}>
                  {formatMessage({ id: 'highSchool.complete_self_registration_finish_button' })}
                </Button>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};
