// eslint-disable-next-line import/order
import React from 'react';

import { Button, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { length } from 'redux-form-validators';
import clsx from 'clsx';

import { CustomStepper } from '../CustomStepper';

// import backgroundImgLeft from 'assets/stageClip-branding-vector 2.png';
import { useLocale } from 'shared/hooks/useLocale/useLocale';
import { StudentLoginHeader } from 'app/studentLoginPage/studentLoginHeader/studentLoginHeader';
import { useStyles } from './HighSchoolSelfRegistration.styles';
// import { GridContainer } from 'shared/components';
import { MainHeading } from 'app/avUserRoot/collabloratorUpload/uploadMedia/eventInformations/EventInformation.styles';
import { InputField } from 'shared/components/controls/input/InputField';
import { SelfRegistrationProps } from './HighSchoolSelfRegistration.types';
import { Form } from 'shared/components/form/Form';
import { email } from 'shared/validators';
import { CheckboxField } from 'shared/components/controls';
import { RecaptchaField } from 'shared/components/controls/recaptchaField/RecaptchaField';

export const SelfRegistrationPage = ({
  onSubmit,
  activeStep,
  instituteLogo,
  isCollective,
  isRetail,
  isLoading,
  queryParamKeys,
  isAllowForAmazonMusic,
}: SelfRegistrationProps) => {
  const styles = useStyles({});
  const { formatMessage } = useLocale();
  const { breakpoints } = useTheme();

  const isMobile = useMediaQuery(breakpoints.down('sm'));
  const initialValues = {
    isAllowForMarketing: true,
  };

  return (
    <>
      {(queryParamKeys === 'instituitionId' ? activeStep === 1 : activeStep === 0) && (
        <div className={styles.mainContainer}>
          <StudentLoginHeader />
          <div className={styles.imgContainer}>
            {instituteLogo && <img src={instituteLogo} alt="institute logo" className={styles.logoImage} />}
          </div>
          <div className={styles.container}>
            <div className={styles.cardWrapper}>
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <CustomStepper
                  isCollective={isCollective}
                  isRetail={isRetail}
                  activeStep={activeStep}
                  isAllowForAmazonMusic={isAllowForAmazonMusic}
                />
                <Typography className={styles.titleContainer}>
                  {formatMessage({ id: 'highSchool.register_welcome' })}
                </Typography>
                <Typography className={styles.subTitle}>
                  {formatMessage({ id: 'highSchool.register_welcome_subtitle' })}
                </Typography>
                <Typography className={styles.supportText}>
                  <div>
                    If you have already registered to receive a clip and need to make a change to your submission,
                    please{' '}
                    <a href="https://stageclip.com/student-support/" target="_blank" rel="noopener noreferrer">
                      contact us.{' '}
                    </a>
                    Do not submit a second registration, as this may delay delivery of your clip.
                  </div>
                  {/* {formatMessage({ id: 'highSchool.register_support' })} */}
                </Typography>
              </div>
              <div className={styles.card}>
                <Form
                  onSubmit={onSubmit}
                  initialValues={initialValues}
                  errorSeparator={2}
                  render={({ handleSubmit, invalid }) => (
                    <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column' }}>
                      <MainHeading className={styles.header}>
                        {formatMessage({ id: 'highSchool.register_title' })}
                      </MainHeading>
                      <InputField
                        label={formatMessage({ id: 'highSchool.graduate_first_name' })}
                        type="text"
                        size="small"
                        name="firstName"
                        fullWidth
                        placeholder="Enter your first name as announced"
                        required
                        validate={[length({ max: 50 })]}
                        className={styles.textLabel}
                        classes={{ root: styles.textFieldRoot }}
                      />
                      <InputField
                        label={formatMessage({ id: 'highSchool.graduate_last_name' })}
                        type="text"
                        size="small"
                        name="lastName"
                        fullWidth
                        placeholder="Enter your last name as announced"
                        required
                        validate={[length({ max: 50 })]}
                        className={styles.textLabel}
                        classes={{ root: styles.textFieldRoot }}
                      />
                      <InputField
                        label={formatMessage({ id: 'highSchool.graduate_email' })}
                        type="email"
                        size="small"
                        name="email"
                        fullWidth
                        placeholder="Enter your email"
                        validate={[email()]}
                        required
                        className={styles.textLabel}
                      />
                      <InputField
                        label="Degree title or course name"
                        type="text"
                        size="small"
                        name="subtitle"
                        fullWidth
                        placeholder="Enter details (optional)"
                        validate={[length({ max: 90 })]}
                        className={styles.textLabel}
                      />

                      <div className={styles.recaptcha}>
                        <RecaptchaField name="token" />
                      </div>

                      <Button
                        type="submit"
                        size="large"
                        className={styles.buttonContainer}
                        disabled={isLoading || invalid}
                      >
                        {isMobile && !isAllowForAmazonMusic
                          ? formatMessage({ id: 'highSchool.register_button_mobile' })
                          : formatMessage({ id: 'highSchool.register_button' })}
                      </Button>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          justifyContent: 'center',
                          textAlign: 'center',
                          marginTop: '16px',
                        }}
                      >
                        <Typography className={styles.emailClipsReadyTitle}>
                          {formatMessage({ id: 'highSchool.register_subtitle' })}
                        </Typography>
                        <div className={styles.checkboxContainer}>
                          <CheckboxField
                            name="isAllowForMarketing"
                            size="small"
                            label={formatMessage({ id: 'highSchool.register_promotion_subtitle' })}
                            className={clsx(
                              isAllowForAmazonMusic ? styles.bottomContainerAmazon : styles.bottomContainer,
                            )}
                          />
                        </div>
                      </div>
                    </form>
                  )}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
