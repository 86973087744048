/* eslint-disable react/no-children-prop */
import React from 'react';
import { Stepper, Step, StepLabel } from '@material-ui/core';
// import { useLocation } from 'react-router-dom';
// import queryString from 'query-string';

import { CustomStepperProps } from './selfRegistration/HighSchoolSelfRegistration.types';
import { useStyles } from './CustomStepper.styles';

export const CustomStepper = ({
  activeStep,
  isCollective,
  isRetail,
  isSelfRegister,
  isAllowForAmazonMusic,
}: CustomStepperProps) => {
  // const { search } = useLocation();
  // const queryParams = queryString.parse(search);
  // const queryParamKeys = Object.keys(queryParams);

  const steps =
    // queryParamKeys[0] === 'instituitionId'
    // ?
    [
      { id: '1' },
      ...(isSelfRegister ? [{ id: '2' }] : []),
      ...((isCollective && !isRetail) || (isAllowForAmazonMusic && !isRetail) ? [{ id: '3' }] : []),
      { id: '4' },
      { id: '5' },
    ];
  // : [
  //     { id: '1' },
  //     ...((isCollective && !isRetail) || (!isAllowForAmazonMusic && !isRetail) ? [{ id: '2' }] : []),
  //     { id: '3' },
  //   ];
  const styles = useStyles();

  return (
    <Stepper activeStep={activeStep} className={styles.stepperContainer}>
      {steps.map(i => (
        <Step key={i.id}>
          <StepLabel classes={{ iconContainer: styles.activeIcon, label: styles.labelText }} children="" />
        </Step>
      ))}
    </Stepper>
  );
};
